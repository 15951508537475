/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'amyQ2HSkkP7Hj1xHZX7hiQ.png': { uri: '/images/amyQ2HSkkP7Hj1xHZX7hiQ.png' },
'm3LZLjypwt34CeSxQJWHBH.png': { uri: '/images/m3LZLjypwt34CeSxQJWHBH.png' },
'xvRxGeuep7Wz9dsPXrEqnN.png': { uri: '/images/xvRxGeuep7Wz9dsPXrEqnN.png' },
'kGLRymZ1JDGYKWkjNSjxwf.jpg': { uri: '/images/kGLRymZ1JDGYKWkjNSjxwf.jpg' },
'qKTBrDDa9dcWHHW1cm4zdw.png': { uri: '/images/qKTBrDDa9dcWHHW1cm4zdw.png' },
'eYwTYiuDrDEVU82jpF3gUR.png': { uri: '/images/eYwTYiuDrDEVU82jpF3gUR.png' },
'nZZty5VGoSyyrtn8qvSqrT.png': { uri: '/images/nZZty5VGoSyyrtn8qvSqrT.png' },
'8vqBmbHHNFGi65p49tXgLf.png': { uri: '/images/8vqBmbHHNFGi65p49tXgLf.png' },
'6JhT4DLTw4fDKrTZdYEB2B.png': { uri: '/images/6JhT4DLTw4fDKrTZdYEB2B.png' },
'bjkbHw3w57uK1vMB5tvceh.png': { uri: '/images/bjkbHw3w57uK1vMB5tvceh.png' },
'tcac5Wv81CSBpGv8zMBtSS.png': { uri: '/images/tcac5Wv81CSBpGv8zMBtSS.png' },
'uMRm4zbZWaKWcwhg93WHBh.png': { uri: '/images/uMRm4zbZWaKWcwhg93WHBh.png' },
'9Uf3qiQoxozEqnTJqrzfTD.png': { uri: '/images/9Uf3qiQoxozEqnTJqrzfTD.png' },
'cbuCAzMqghYJynnUFoEbvj.jpg': { uri: '/images/cbuCAzMqghYJynnUFoEbvj.jpg' },
'eJaWcESTsh3tTSjeKbG98E.png': { uri: '/images/eJaWcESTsh3tTSjeKbG98E.png' },
'1k8ugseomAwagpLT5Ftzau.jpg': { uri: '/images/1k8ugseomAwagpLT5Ftzau.jpg' },
'nJMaJTNUaPeCMLKoaxHKsA.jpg': { uri: '/images/nJMaJTNUaPeCMLKoaxHKsA.jpg' },
'rD8RU8Z17gyFqq7LLuaQ9z.png': { uri: '/images/rD8RU8Z17gyFqq7LLuaQ9z.png' }
}

export default imageStaticSourcesByFileName
